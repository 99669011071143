import axios from 'axios';
// import { getToken } from './getToken';

const needAuthorization = ['home', 'perfil'];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// getToken().then((token) => {
//   api.defaults.headers.Authorization = `Bearer ${token}`;
// });

api.interceptors.response.use(
  (resposta) => resposta,
  (error) => {
    const location = window.location.pathname.split('/')[1];

    if (
      error?.response?.status === 401 &&
      needAuthorization.includes(location)
    ) {
      window.location.reload(true);
    }

    return Promise.reject(error);
  }
);

export default api;
