/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Spinner } from 'reactstrap';

import { Container } from './styles';

interface UnauthContainerProps {}
const Loading = () => {
  return (
    <Container>
      <Spinner type="grow" color="info" size="sm" />
      <Spinner type="grow" color="success" size="sm" />
      <Spinner type="grow" color="primary" size="sm" />
      <Spinner type="grow" color="danger" size="sm" />
      <Spinner type="grow" color="dark" size="sm" />
    </Container>
  );
};

export default Loading;
