const asyncStoragePrefix = '@NossoBB:';

const ASYNC_STORAGE_KEYS = {
  userAlreadyConfiguredRoom: 'userAlreadyConfiguredRoom',
  userAlreadyConfiguredProfile: 'userAlreadyConfiguredProfile',
  eventCreatedData: 'eventCreatedData',
  giftsCreatedData: 'giftsCreatedData',
  invitesCreatedData: 'invitesCreatedData',
};

export const getLocalStorageUserAlreadyConfiguredRoomValue = () => {
  return localStorage.getItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.userAlreadyConfiguredRoom
  );
};

export const setLocalStorageUserAlreadyConfiguredRoomValue = (
  value: string
) => {
  return localStorage.setItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.userAlreadyConfiguredRoom,
    value
  );
};

export const getLocalStorageUserAlreadyConfiguredProfileValue = () => {
  return localStorage.getItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.userAlreadyConfiguredProfile
  );
};

export const setLocalStorageUserAlreadyConfiguredProfileValue = (
  value: string
) => {
  return localStorage.setItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.userAlreadyConfiguredProfile,
    value
  );
};

export const setLocalStorageEventCreatedData = (value: string) => {
  return localStorage.setItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.eventCreatedData,
    value
  );
};

export const getLocalStorageEventCreatedData = () => {
  return JSON.parse(
    localStorage.getItem(
      asyncStoragePrefix + ASYNC_STORAGE_KEYS.eventCreatedData
    )
  );
};

export const setLocalStorageGiftsData = (value: string) => {
  return localStorage.setItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.giftsCreatedData,
    value
  );
};

export const getLocalStorageGiftsData = () => {
  return JSON.parse(
    localStorage.getItem(
      asyncStoragePrefix + ASYNC_STORAGE_KEYS.giftsCreatedData
    )
  );
};

export const setLocalStorageInvitesData = (value: string) => {
  return localStorage.setItem(
    asyncStoragePrefix + ASYNC_STORAGE_KEYS.invitesCreatedData,
    value
  );
};

export const getLocalStorageInvitesData = () => {
  return JSON.parse(
    localStorage.getItem(
      asyncStoragePrefix + ASYNC_STORAGE_KEYS.invitesCreatedData
    )
  );
};

export const clearStepByStepLocalStorage = () => {
  setLocalStorageUserAlreadyConfiguredRoomValue('false');
  setLocalStorageUserAlreadyConfiguredProfileValue('false');
  setLocalStorageEventCreatedData(null);
  setLocalStorageGiftsData(null);
  setLocalStorageInvitesData(null);
};
