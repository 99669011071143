/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import App from './App';
import { AuthProvider } from './providers/AuthProvider';
import awsconfig from './aws-exports';
import firebaseConfig from './firebase-config';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/now-ui-dashboard.scss?v=1.4.0';
import './assets/css/demo.css';

// Intialize AWS
Amplify.configure(awsconfig);

// Initialize Firebase
if (process.env.NODE_ENV === 'production') {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, 'screen_view');
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReactQueryConfigProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ReactQueryConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
