import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './providers/AuthProvider';

import 'react-toastify/dist/ReactToastify.css';

// Remember to always codesplit routes
const UnauthenticatedApp = React.lazy(
  () => import('./pages/UnauthenticatedApp')
);
const AuthenticatedApp = React.lazy(() => import('./pages/AuthenticatedApp'));
const WizardApp = React.lazy(() => import('./pages/WizardApp'));

const App: React.FC = () => {
  const auth = useAuth()?.auth;
  const user = useAuth()?.user;

  if (auth == null) {
    return (
      <React.Suspense fallback={<div />}>
        <UnauthenticatedApp />
      </React.Suspense>
    );
  }

  if (user?.userRoles[0].wizardStep !== null) {
    return (
      <React.Suspense fallback={<div />}>
        <WizardApp />
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback={<div />}>
      <AuthenticatedApp />
      <ToastContainer autoClose={3000} />
    </React.Suspense>
  );
};

export default React.memo(App);
